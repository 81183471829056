import firebase from 'firebase';
import React, { Component, FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import ReduxState from '../redux/types/redux-state';
import PeerJS from 'peerjs';
import VideoStream from '../components/video-stream';
import { Grid } from '@material-ui/core';

interface CallComponentProps extends RouteComponentProps<{ id: string }> {}

interface CallComponentState {
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
  remoteStreams: {
    uid: string;
    email: string;
    stream: MediaStream;
  }[];
  localStream: MediaStream | null;
}

class CallComponent extends Component<CallComponentProps, CallComponentState> {
  private roomMembers: Map<string, PeerJS.MediaConnection>;
  private peer: PeerJS;
  private user: firebase.User | null;

  constructor(props: CallComponentProps) {
    super(props);

    this.peer = new PeerJS(firebase.auth().currentUser?.uid, {
      host: 'localhost',
      port: 9000,
      path: '/'
    });

    this.user = firebase.auth().currentUser;
    this.roomMembers = new Map();

    this.state = {
      isAudioEnabled: false,
      isVideoEnabled: true,
      localStream: null,
      remoteStreams: []
    };

    const constraints: MediaStreamConstraints = {
      audio: this.state.isAudioEnabled,
      video: this.state.isVideoEnabled
    };

    navigator.getUserMedia(constraints, this.userMediaCallback, err => {
      console.log('Error getting user media: ', err);
    });

    window.onbeforeunload = this.cleanUp;
  }

  componentWillUnmount() {
    this.cleanUp();
  }

  cleanUp() {
    this.state.localStream?.getTracks().forEach(track => track.stop());
    this.roomMembers.forEach((v, k) => v.close());
    this.peer.disconnect();
  }

  userMediaCallback = (stream: MediaStream) => {
    this.setState({ localStream: stream });

    firebase
      .firestore()
      .collection('calls')
      .doc(this.props.match.params.id)
      .collection('members')
      .onSnapshot(snapshot => {
        snapshot.docs.forEach(doc => {
          const uid = doc.data()?.uid;
          if (uid != this.user?.uid) {
            const call = this.peer.call(uid, stream, {
              metadata: {
                caller: {
                  uid: this.user?.uid,
                  email: this.user?.email
                },
                reciever: doc.data()
              }
            });
            call.on('stream', remoteStream => {
              if (remoteStream) {
                this.setState({
                  remoteStreams: [
                    ...this.state.remoteStreams,
                    { uid: doc.data()?.uid, email: doc.data()?.email, stream: remoteStream }
                  ]
                });
              }
            });
            call.on('close', () => {
              this.roomMembers.delete(uid);
              this.setState({
                remoteStreams: this.state.remoteStreams.filter(stream => stream.uid !== uid)
              });
            });
            this.roomMembers.set(uid, call);
          }
        });
        snapshot.docChanges().forEach(change => {
          if (change.type === 'removed') {
            this.roomMembers.delete(change.doc.data()?.uid);
          }
        });
      });

    this.peer.on('call', call => {
      call.answer(stream);
    });
  };

  render() {
    return (
      <Grid
        container
        spacing={2}
        style={{ padding: 10, backgroundColor: '#555', minHeight: '100vh' }}
      >
        <Grid item xs={6} md={4} lg={3}>
          <VideoStream
            border={'#27ae60'}
            label={this.user?.email}
            stream={this.state.localStream}
          />
        </Grid>
        {this.state.remoteStreams
          .filter(video => video.stream.active)
          .map((video, i) => (
            <Grid key={i} item xs={6} md={4} lg={3}>
              <VideoStream border={'#333333'} label={video.email} stream={video.stream} />
            </Grid>
          ))}
      </Grid>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({});

const mapActionsToProps = {};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(CallComponent));
