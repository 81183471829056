import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { displayAlert } from '../redux/actions/alert-actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import firebase from 'firebase';
import ReduxState from '../redux/types/redux-state';

interface LoginComponentProps extends RouteComponentProps {
  displayAlert: (message: string, type: string, redirect: string | null) => void;
}

const LoginComponent: FC<LoginComponentProps> = (props: LoginComponentProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [remember, setRemember] = useState<boolean>(true);

  const toggleRemember = () => setRemember(!remember);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const handleEmailChange = (e: any) => setEmail(e.target.value);
  const handlePasswordChange = (e: any) => setPassword(e.target.value);

  const login = async (e: any) => {
    e.preventDefault();
    if (!email || !password) {
      return props.displayAlert('Email and password are required', 'error', '/register');
    }
    try {
      const auth: firebase.auth.Auth = firebase.auth();
      auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      const creds = await auth.signInWithEmailAndPassword(email, password);

      firebase
        .firestore()
        .collection('users')
        .where('uid', '==', creds.user?.uid)
        .onSnapshot(snapshot => {
          snapshot.forEach(res => {
            res.ref.update({ online: true });
          });
        });

      props.history.push('/');
    } catch (err) {
      setPassword('');
      props.displayAlert(err.message, 'error', null);
    }
  };

  const passwordProps = {
    endAdornment: (
      <InputAdornment position='start'>
        <IconButton aria-label='toggle password visibility' onClick={toggleShowPassword}>
          {!showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  };

  return (
    <div id='login-page'>
      <div className='login-form-wrapper'>
        <Card style={{ padding: 20 }} className='z-depth-3'>
          <CardContent>
            <Grid container justify='center' alignItems='center'>
              <Grid item>
                <h1>Login</h1>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <form onSubmit={login}>
              <FormGroup>
                <FormControl style={{ marginBottom: 20 }}>
                  <TextField
                    id='email-input'
                    label='Email Address'
                    value={email}
                    onChange={handleEmailChange}
                  />
                </FormControl>
                <FormControl style={{ marginBottom: 40 }}>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    id='password-input'
                    label='Password'
                    InputProps={passwordProps}
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </FormControl>
                <FormControl style={{ marginBottom: 30 }}>
                  <Button variant={'contained'} color='default' onClick={login}>
                    Login
                  </Button>
                </FormControl>
                <FormControl>
                  <Grid container justify={'center'}>
                    <p>
                      Don't have an account? <Link to={'/register'}>Sign up here.</Link>
                    </p>
                  </Grid>
                </FormControl>
                <FormControl style={{ marginBottom: 40 }}>
                  <Grid container justify={'center'}>
                    <p>
                      Forgot your password? <Link to='/forgot-password'>Reset it.</Link>
                    </p>
                  </Grid>
                </FormControl>
              </FormGroup>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({});

const mapActionsToProps = {
  displayAlert
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(LoginComponent));
