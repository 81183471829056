import { Dispatch } from 'redux';
import { ActionTypes } from '../types/action-types';
import { Notification } from '../types/notification';

export const addNotification = (notification: any) => {
  return (dispatch: Dispatch, getState: () => any) => {};
};

export const setNotifications = (notifications: Notification[]) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    dispatch({
      type: ActionTypes.SET_NOTIFICATIONS,
      payload: { notifications }
    });
  };
};
