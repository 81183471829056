import React, { FC, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormControl, FormGroup, IconButton, TextField } from '@material-ui/core';
import { algoliaConfig } from './../config';
import { CallOutlined } from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import algolia from 'algoliasearch';
import firebase from 'firebase';

interface SearchUsersProps extends RouteComponentProps {
  callUser: (uid: string) => Promise<void>;
}

const SearchUsers: FC<SearchUsersProps> = (props: SearchUsersProps): JSX.Element => {
  const currentUser = firebase.auth().currentUser;
  const algoliaClient = algolia(algoliaConfig.applicationId, algoliaConfig.adminApiKey);
  const index = algoliaClient.initIndex('users');
  const [users, setUsers] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearchTermChange = (e: any) => {
    setSearchTerm(e.target.value);
    index.search(e.target.value).then(res => setUsers(res.hits));
  };

  return (
    <>
      <div>
        <FormGroup>
          <FormControl style={{ marginBottom: 20 }}>
            <TextField
              id='email-input'
              label='Search For Users'
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </FormControl>
        </FormGroup>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead style={{ display: 'none' }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .filter(user => user.objectID !== currentUser?.uid)
                .map((user, i) => (
                  <TableRow key={i}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <IconButton onClick={e => props.callUser(user.objectID)}>
                        <CallOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default withRouter(SearchUsers);
