/********/
import login from './pages/login';
import register from './pages/register';
import forgotPassword from './pages/forgot-password';
import nonuserHome from './pages/nonuser-home';
/********/
import userHome from './pages/user-home';
import userProfile from './pages/user-profile';
import userSettings from './pages/user-settings';
import call from './pages/call';
/********/

export const authedRoutes = [
  { path: '/', exact: true, component: userHome },
  { path: '/profile', exact: true, component: userProfile },
  { path: '/settings', exact: true, component: userSettings },
  { path: '/call/:id', exact: true, component: call }
];

export const unAuthedRoutes = [
  { path: '/login', exact: true, component: login },
  { path: '/register', exact: true, component: register },
  { path: '/forgot-password', exact: true, component: forgotPassword },
  { path: '/', exact: true, component: nonuserHome }
];
