import { Alert } from '../types/alert';
import { Action } from 'redux';
import { ActionTypes } from '../types/action-types';

const initialState: Alert = {
  type: null,
  message: null,
  show: false,
  redirect: null
};

export const alertReducer = (state = initialState, action: Action<ActionTypes> | any) => {
  switch (action.type) {
    case ActionTypes.DISPLAY_ALERT:
      return {
        ...state,
        show: true,
        type: action.payload.type,
        message: action.payload.message,
        redirect: action.payload.redirect
      };

    case ActionTypes.CLEAR_ALERT:
      return {
        ...state,
        show: false,
        message: '',
        redirect: ''
      };

    default:
      return state;
  }
};
