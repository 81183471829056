import React, { FC, useContext } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar, makeStyles } from '@material-ui/core';
import { useHistory, withRouter } from 'react-router';
import ReduxState from '../redux/types/redux-state';
import { connect } from 'react-redux';
import { Alert } from '../redux/types/alert';

interface GlobalAlertProps {
  alert: Alert;
}

const useStyles = makeStyles({
  alert: {
    cursor: 'pointer'
  }
});

const GlobalAlertComponent: FC<GlobalAlertProps> = (props: GlobalAlertProps): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();

  const handleClick = (e: any) => {
    if (props.alert.redirect) {
      history.push(props.alert.redirect);
    }
  };

  return (
    <Snackbar
      open={props.alert.show}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className={classes.alert}
      onClick={handleClick}
    >
      <MuiAlert elevation={6} variant={'filled'} severity={props.alert.type || 'info'}>
        {props.alert.message}
      </MuiAlert>
    </Snackbar>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  alert: state.alert
});

const mapActionsToProps = {};

export const GlobalAlert = withRouter(
  connect(mapStateToProps, mapActionsToProps)(GlobalAlertComponent)
);
