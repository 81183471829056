const config = (name: string): string => {
  return process.env[name] || '';
};

export const firebaseConfig = {
  apiKey: config('REACT_APP_FIREBASE_KEY'),
  authDomain: config('REACT_APP_FIREBASE_AUTH_DOMAIN'),
  projectId: config('REACT_APP_FIREBASE_PROJECT_ID'),
  storageBucket: config('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: config('REACT_APP_FIREBASE_MSG_SENDER_ID'),
  appId: config('REACT_APP_FIREBASE_APP_ID'),
  measurementId: config('REACT_APP_FIREBASE_MEASUREMENT_ID')
};

export const algoliaConfig = {
  applicationId: config('REACT_APP_ALGOLIA_APP_ID'),
  adminApiKey: config('REACT_APP_ALGOLIA_ADMIN_KEY'),
  searchApiKey: config('REACT_APP_ALGOLIA_SEARCH_KEY'),
  usageApiKey: config('REACT_APP_ALGOLIA_USAGE_KEY')
};
