import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { firebaseConfig } from './config';
import { GlobalAlert } from './components/global-alert';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import App from './app';
import firebase from 'firebase';
import store from './redux/store';
import 'firebase/auth';
import 'firebase/firestore';
import './assets/styles/main.scss';

firebase.initializeApp(firebaseConfig);
firebase.firestore();

ReactDOM.render(
  <Fragment>
    <BrowserRouter>
      <Provider store={store}>
        <GlobalAlert />
        <App />
      </Provider>
    </BrowserRouter>
  </Fragment>,
  document.getElementById('root')
);
