import React, { FC, Fragment, useEffect, useState } from 'react';
import { authedRoutes, unAuthedRoutes } from './routes';
import { Route, Switch, Redirect, RouteComponentProps, withRouter } from 'react-router';
import firebase, { User } from 'firebase';
import TopBar from './components/topbar';
import { connect } from 'react-redux';
import ReduxState from './redux/types/redux-state';
import { setNotifications } from './redux/actions/notification-actions';
import { Notification } from './redux/types/notification';

interface Props extends RouteComponentProps {
  setNotifications: (notifications: Notification[]) => Promise<void>;
}

const App: FC<Props> = (props: Props): JSX.Element => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user: User | null) => {
      setIsLoggedIn(user ? true : false);
      setIsLoading(false);

      if (user) {
        firebase
          .firestore()
          .collection('users')
          .where('uid', '==', user.uid)
          .get()
          .then(querySnapnot => {
            if (!querySnapnot.empty) {
              querySnapnot.docs[0].ref
                .collection('invites')
                .orderBy('time', 'desc')
                .onSnapshot(snapshot => {
                  props.setNotifications(
                    snapshot.docs.map(doc => {
                      const data = doc.data();
                      return {
                        id: doc.id,
                        fromId: data?.fromId,
                        fromEmail: data?.fromEmail,
                        callId: data?.callId,
                        time: data?.time
                      };
                    })
                  );
                });
            }
          });
      }
    });
  }, [isLoggedIn]);

  const loggedInRoutes: JSX.Element = (
    <Fragment>
      <TopBar />
      <Switch>
        {authedRoutes.map((route, i) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
        <Route render={() => <Redirect to={'/'} />} />
      </Switch>
    </Fragment>
  );

  const notLoggedInRoutes: JSX.Element = (
    <Fragment>
      <Switch>
        {unAuthedRoutes.map((route, i) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
        <Route render={() => <Redirect to={'/'} />} />
      </Switch>
    </Fragment>
  );

  return isLoading ? <></> : isLoggedIn ? loggedInRoutes : notLoggedInRoutes;
};

const mapStateToProps = (state: ReduxState) => ({});

const mapActionsToProps = {
  setNotifications
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(App));
