import React, { FC, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  makeStyles,
  List,
  ListItem,
  SwipeableDrawer,
  ListItemIcon,
  ListItemText,
  Divider
} from '@material-ui/core';
import {
  GroupOutlined,
  ExitToApp,
  SettingsOutlined,
  HomeOutlined,
  AddOutlined,
  PlaylistAdd,
  AccountCircleOutlined
} from '@material-ui/icons';
import firebase from 'firebase';

interface SideBarProps extends RouteComponentProps {
  setIsSideBarCollapsed: (value: boolean) => void;
  collapsed: boolean;
}

const useStyles = makeStyles({
  list: {
    width: 250
  }
});

const SideBar: FC<SideBarProps> = (props: SideBarProps): JSX.Element => {
  const classes = useStyles();
  const auth: firebase.auth.Auth = firebase.auth();

  const logout = async (): Promise<void> => {
    firebase
      .firestore()
      .collection('users')
      .where('uid', '==', auth.currentUser?.uid)
      .onSnapshot(snapshot => {
        snapshot.forEach(res => {
          res.ref.update({ online: false });
        });
      });

    await auth.signOut();
  };

  const navigate = (path: string): void => {
    props.history.push(path);
    props.setIsSideBarCollapsed(true);
  };

  const SidebarList = () => (
    <List className={classes.list}>
      <ListItem button onClick={e => navigate('/')}>
        <ListItemIcon>
          <HomeOutlined />
        </ListItemIcon>
        <ListItemText primary={'Home'} />
      </ListItem>
      <Divider />
      <ListItem button onClick={e => navigate('/profile')}>
        <ListItemIcon>
          <AccountCircleOutlined />
        </ListItemIcon>
        <ListItemText primary={'Profile'} />
      </ListItem>
      <Divider />
      <ListItem button onClick={e => navigate('/settings')}>
        <ListItemIcon>
          <SettingsOutlined />
        </ListItemIcon>
        <ListItemText primary={'Settings'} />
      </ListItem>
      <Divider />
      <ListItem button onClick={logout}>
        <ListItemIcon>
          <ExitToApp style={{ transform: 'rotate(180deg)' }} />
        </ListItemIcon>
        <ListItemText primary={'Logout'} />
      </ListItem>
    </List>
  );

  return (
    <Fragment>
      <SwipeableDrawer
        anchor={'left'}
        open={!props.collapsed}
        onClose={() => props.setIsSideBarCollapsed(true)}
        onOpen={() => props.setIsSideBarCollapsed(false)}
      >
        <SidebarList />
      </SwipeableDrawer>
    </Fragment>
  );
};

export default withRouter(SideBar);
