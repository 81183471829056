import React, { FC, useEffect } from 'react';
import { Container } from '@material-ui/core';
import firebase, { User } from 'firebase';
import { withRouter, RouteComponentProps } from 'react-router';
import SearchUsers from '../components/search-users';
import { connect } from 'react-redux';
import ReduxState from '../redux/types/redux-state';
import { displayAlert } from '../redux/actions/alert-actions';

interface SearchGroupsProps extends RouteComponentProps {
  displayAlert: (message: string, type: string, redirect: string | null) => void;
}

const SearchGroups: FC<SearchGroupsProps> = (props: SearchGroupsProps): JSX.Element => {
  const firestore: firebase.firestore.Firestore = firebase.firestore();
  const user: User | null = firebase.auth().currentUser;

  const callUser = async (uid: string) => {
    const call = firestore.collection('calls').doc();

    await call.collection('chat').add({
      from: null,
      message: 'Call has been started',
      time: Date.now()
    });

    await call.collection('members').add({
      uid: user?.uid,
      email: user?.email
    });

    firestore
      .collection('users')
      .where('uid', '==', uid)
      .onSnapshot(querySnapsnot => {
        if (!querySnapsnot.empty) {
          querySnapsnot.docs[0].ref.collection('invites').add({
            callId: call.id,
            fromEmail: user?.email,
            fromId: user?.uid,
            time: Date.now()
          });
        }
      });

    props.displayAlert('An invite has been sent.', 'success', '/');
    props.history.push(`/call/${call.id}`);
  };

  return (
    <Container maxWidth={'sm'} style={{ marginTop: 40, marginBottom: 100 }}>
      <SearchUsers callUser={callUser} />
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({});

const mapActionsToProps = {
  displayAlert
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(SearchGroups));
