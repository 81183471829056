import React, { FC, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

interface NonUserHomeProps extends RouteComponentProps {}

const NonUserHome: FC<NonUserHomeProps> = (props: NonUserHomeProps) => {
  props.history.push('/login');
  return <></>;
};

export default withRouter(NonUserHome);
