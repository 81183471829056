import { Dispatch } from 'redux';
import { ActionTypes } from '../types/action-types';
import ReduxState from '../types/redux-state';

export const displayAlert = (message: string, type: string, redirect: string | null = null) => {
  return (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch({
      type: ActionTypes.DISPLAY_ALERT,
      payload: { message, type, redirect }
    });
    setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_ALERT
      });
    }, 5000);
  };
};
