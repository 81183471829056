import React, { FC, Fragment, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import firebase, { User } from 'firebase';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import SideBar from './sidebar';
import {
  AccountCircleOutlined,
  CancelOutlined,
  NotificationsOutlined,
  PhoneCallbackOutlined
} from '@material-ui/icons';
import ReduxState from '../redux/types/redux-state';
import { connect } from 'react-redux';
import { Notification } from '../redux/types/notification';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  appbar: {
    background: '#0984e3'
  },
  typography: {
    padding: theme.spacing(2)
  }
}));

interface TopBarProps extends RouteComponentProps {
  notifications: Array<Notification>;
}

const TopBar: FC<TopBarProps> = (props: TopBarProps): JSX.Element => {
  const classes = useStyles();
  const user: User | null = firebase.auth().currentUser;

  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleIsSideBarCollapsed = () => setIsSideBarCollapsed(!isSideBarCollapsed);

  const deleteNotification = (id: string) => {
    firebase
      .firestore()
      .collection('users')
      .where('uid', '==', user?.uid)
      .get()
      .then(querySnapnot => {
        if (!querySnapnot.empty) {
          querySnapnot.docs[0].ref.collection('invites').doc(id).delete();
        }
      });
  };

  const acceptCall = (callId: string, notificationId: string) => {
    firebase.firestore().collection('calls').doc(callId).collection('members').add({
      uid: user?.uid,
      email: user?.email
    });

    closeNotifications();
    deleteNotification(notificationId);
    props.history.push(`/call/${callId}`);
  };

  const openNotifications = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeNotifications = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Fragment>
      <div className={classes.grow}>
        <AppBar position='static' className={classes.appbar}>
          <Toolbar>
            <div>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='menu'
                onClick={toggleIsSideBarCollapsed}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div className={classes.grow} />
            <div>
              <IconButton
                aria-label='menu'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                color='inherit'
                onClick={openNotifications}
              >
                <Badge badgeContent={props.notifications.length} color='secondary'>
                  <NotificationsOutlined color={'inherit'} />
                </Badge>
              </IconButton>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={closeNotifications}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                {!props.notifications.length && (
                  <Typography className={classes.typography} variant={'body1'}>
                    You do not have any notifications.
                  </Typography>
                )}
                {props.notifications.map((notification, i) => (
                  <div key={i} style={{ display: 'flex', padding: 4 }}>
                    <Typography className={classes.typography} variant={'body2'}>
                      <b>{notification.fromEmail}</b> has invited you to a call. -{' '}
                      {new Date(notification.time).toLocaleTimeString()}
                    </Typography>
                    <IconButton onClick={e => acceptCall(notification.callId, notification.id)}>
                      <PhoneCallbackOutlined color={'primary'} />
                    </IconButton>
                    <IconButton onClick={e => deleteNotification(notification.id)}>
                      <CancelOutlined color={'error'} />
                    </IconButton>
                  </div>
                ))}
              </Popover>
              <IconButton
                aria-label='menu'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                color='inherit'
                onClick={() => props.history.push('/profile')}
              >
                <AccountCircleOutlined color={'inherit'} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <SideBar collapsed={isSideBarCollapsed} setIsSideBarCollapsed={setIsSideBarCollapsed} />
      </div>
    </Fragment>
  );
};

const mapStateToPros = (state: ReduxState) => ({
  notifications: state.notifications
});

const mapActionsToProps = {};

export default withRouter(connect(mapStateToPros, mapActionsToProps)(TopBar));
