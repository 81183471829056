import React, { Component, Fragment, RefObject } from 'react';

interface VideoStreamProps {
  stream: MediaStream | null;
  label: string | null | undefined;
  border: string;
}

class VideoStream extends Component<VideoStreamProps> {
  private videoRef: RefObject<HTMLVideoElement>;

  constructor(props: VideoStreamProps) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.startVideo();
  }

  componentDidUpdate() {
    this.startVideo();
  }

  startVideo() {
    if (this.videoRef.current) {
      this.videoRef.current.srcObject = this.props.stream;
    }
  }

  render() {
    return (
      <div style={{ position: 'relative', padding: 0 }}>
        <video
          style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            borderRadius: '0px',
            border: `${this.props.border} 2px solid`,
            left: 0,
            top: 0
          }}
          ref={this.videoRef}
          autoPlay
        />
        <div
          style={{
            zIndex: 1000,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: 25,
            backgroundColor: '#222',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            padding: 5
          }}
        >
          <h4 style={{ color: '#fff', margin: 5 }}> {this.props.label}</h4>
        </div>
      </div>
    );
  }
}

export default VideoStream;
