import { Action } from 'redux';
import { ActionTypes } from '../types/action-types';

const initialState: any = [];

export const notificationReducer = (state = initialState, action: Action<ActionTypes> | any) => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATIONS:
      return action.payload.notifications;
    default:
      return state;
  }
};
